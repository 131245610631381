<template>
    <custom-table
        :page_filters="filters"
        :filters="filters"
        :headers_items="headerItems"
        :loading="loading || !landing_categories.length"
        :content-type="contentType"
        :items="items"
        :options="tableOptions"
        :table-classes="'category-content'"
        @getData="getList($event)"
        @selectAllCheckbox="selectAllCheckbox($event)"
    >
        <template #customModals>
            <confirm-modal
                :name="'delete-category-modal'"
                :title="'Delete category'"
                :content="'Are you sure?'"
                @onConfirmClick="deleteCategory($event)"
            />
        </template>
    </custom-table>
</template>

<script>
import contentBaseMixin from '@/mixins/content/contentBaseMixin'
import categoryBaseMixins from '@/mixins/content/categoryBaseMixins';

import { headerItems } from '@/helpers/constants/landing-category/content-table-header-items';

import {
    ACTION_GET_CATEGORY_GROUP,
    ACTION_DELETE_CATEGORY_GROUP
} from '@/store/modules/content/content-category/action-types'

import { mapContentCategoryFields } from '@/store/modules/content/content-category'
import { createNamespacedHelpers } from 'vuex';
import { debounce, isEqual } from 'lodash';

const {
    mapActions: mapContentСategoriesActions
} = createNamespacedHelpers('contentCategory');

export default {
    name: 'IndexVue',
    mixins: [
        contentBaseMixin,
        categoryBaseMixins
    ],
    data() {
        return {
            page_filters: {
                sort_by: 'category_name',
                sort_order: 'ASC'
            },
            tableOptions: {
                filters: false,
                content: true,
                toolbar: false,
                hiddenModals: false
            },
            currentRoute: 'content-landing-categories',
            filters: {}
        }
    },
    computed: {
        ...mapContentCategoryFields([
            'landing_categories',
            'loading'
        ]),
        headerItems() {
            return headerItems
        },
        items() {
            return {
                data: this.landing_categories
            }
        }
    },
    watch: {
        '$route.query': debounce(function (val) {
            if (this.$route.name !== 'content-landing-categories') return
            if (isEqual(this.$route.query, this.filters)) return
            this.filters = {
                ...val
            }
            this.getList(val)
        }, 50)
    },
    activated() {
        this.getList(this.filters)
        this.$bus.$on('actionEdit', (row) => {
            this.$router.push({
                name: 'content-landing-categories-edit',
                params: {
                    id: row.id
                }
            })
        })
    },
    deactivated() {
        this.$bus.$off('actionEdit')
    },
    methods: {
        ...mapContentСategoriesActions([
            ACTION_GET_CATEGORY_GROUP,
            ACTION_DELETE_CATEGORY_GROUP
        ]),
        async getList(filters) {
            await this[ACTION_GET_CATEGORY_GROUP]({
                path: 'landing',
                data: filters
            })
        },
        async deleteCategory(data) {
            await this[ACTION_DELETE_CATEGORY_GROUP]({
                path: 'landing',
                id: data.id
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
