export default {
    data() {
        return {
            errorText: '',
            errorsSubCategories: {}
        }
    },
    activated() {
        this.resetError()
    },
    methods: {
        resetError() {
            this.errorText = ''
        },
        setSubError(id, error) {
            this.$set(this.errorsSubCategories, id, error)
        },
        resetSubError(id) {
            this.$set(this.errorsSubCategories, id, '')
        }
    }
}
