<template>
    <div class="category-input__block subcategory-input__block">
        <form-input
            v-model="subcategory_name"
            :field.sync="subcategory_name"
            name="sub-category-name"
            label="Subcategory name"
            legend="Subcategory name"
            :errors="errors"
            :is-valid="isValid"
            @onChange="onChange($event)"
        />
        <div class="subcategory-info">
            <p
                v-if="category.posts_count || category.posts_count === 0"
                :class="category.id ? 'input__block-post_count' : 'input__block-post_count-hide'"
            >
                {{ category.posts_count }}
            </p>
            <span
                v-if="!category.id"
                default
                type="button"
                class="input__block-button text-button color-blue"
                @click="onSaveClick"
            >
                Save
            </span>
            <span
                v-else-if="category.id && showUpdate === id"
                class="input__block-button text-button color-blue"
                @click="onUpdateClick"
            >
                Update
            </span>
            <div
                v-if="id && category.posts_count === 0"
                class="input__block-garbage text-button color-orange"
                @click="$bus.$emit('openModal', 'delete-subcategory-modal', { positionId: id, id: category.id })"
            >
                <garbage />
            </div>
            <div
                v-else
                class="input__block-garbage"
            />
        </div>
    </div>
</template>

<script>
import Garbage from '@/icons/Garbage';

export default {
    name: 'NewSubCategory',
    components: {
        Garbage
    },
    props: ['value', 'errors', 'isValid', 'config', 'disabled', 'id', 'category'],
    data() {
        return {
            default_name: this.value,
            subcategory_name: this.value,
            showUpdate: false
        }
    },
    watch: {
        value(val) {
            this.subcategory_name = val
            if (this.showUpdate === false) this.default_name = val
            if (val === this.default_name) this.showUpdate = false
        }
    },
    mounted() {
        this.showUpdate = false
        this.$bus.$on('hideUpdateButton', ({ id }) => {
            if (this.category.id === id) this.showUpdate = false
        })
    },
    activated() {
        this.showUpdate = false
        this.$bus.$on('hideUpdateButton', ({ id }) => {
            if (this.category.id === id) this.showUpdate = false
        })
    },
    destroyed() {
        this.$bus.$off('hideUpdateButton')
    },
    deactivated() {
        this.$bus.$off('hideUpdateButton')
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event)
            this.$emit('input', event)
            this.showUpdate = this.id
        },
        onSaveClick() {
            this.$emit('saveSubCategory', { positionId: this.id, title: this.subcategory_name })
            if (this.isValid) {
                this.default_name = this.subcategory_name
                this.showUpdate = false
            }
        },
        onUpdateClick() {
            this.$emit('updateSubCategory', { positionId: this.id, id: this.category.id, title: this.subcategory_name })
            if (this.isValid) {
                this.default_name = this.subcategory_name
                this.showUpdate = false
            }
        }
    }
}
</script>

<style scoped lang="scss">
.subcategory-input__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .form-input__fieldset {
        width: 75%;
        margin: 0 !important;
    }
    .subcategory-info {
        width: 25%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .input__block-garbage {
        margin-left: 10px;
        cursor: pointer;
        width: 14px;
        height: 17px;
        svg {
            width: 14px;
            height: 17px;
        }
    }
    .input__block-button {
        margin-left: 10px;
        cursor: pointer;
    }
    .input__block-post_count {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 15px;
        font-size: 14px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: rgba(255,84,4,.1);
        color: $orange;
    }
    .input__block-post_count-hide {
        background-color: #fff;
        color: #fff;
        display: flex;
        width: 30px;
        height: 30px;
        margin: 0 0 0 15px;
    }
}
</style>
