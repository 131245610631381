<template>
    <div class="category-input__block">
        <form-input
            v-model="category_name"
            :field.sync="category_name"
            name="category-name"
            :label="label + ' name'"
            :legend="label + ' name'"
            :errors="errors"
            :is-valid="isValid"
            @onChange="onChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'NewCategory',

    props: ['value', 'errors', 'isValid', 'config', 'disabled', 'label'],
    data() {
        return {
            category_name: this.value
        }
    },
    watch: {
        value(val) {
            this.category_name = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event)
            this.$emit('input', event)
        }
    }
}
</script>

<style>

</style>
